import React from 'react';

const Header = () => {
  return (
    <header className="style-7">
      <div className="content">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="info mt-5 mt-lg-0">
                <div className="section-head style-4">
                  <div className="top-title">
                    <img src="/assets/img/line_l.png" alt="" />
                    <h5> Une Solution Éprouvée </h5>
                  </div>
                </div>
                <h1>
                  Solutions <br /> <span className="num"> #01 </span> <span className="text-grad"> de Monitoring et d'Analyse </span> 
                </h1>
                <div className="d-flex flex-wrap">
                  <a href="/page-contact-app" className="btn btn-icon-circle rounded-pill bg-blue7 fw-bold text-white me-4" target="_blank">
                    <small> Allons-y ! <i className="fas fa-long-arrow-alt-right"></i> </small>
                  </a>
                  <div className="inf mt-3 mt-lg-0">
                    <p className="color-999">  Email </p>
                    <a href="/page-contact-app" className="fw-bold"> contact@voipservices.fr </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img mt-4 mt-lg-0">
                <img src="/assets/img/header/head_img7.png" alt="" />
                <img src="/assets/img/header/head7_rock.png" alt="" className="rock7 slide_up_down" />
              </div>
            </div>
            <div className="col-12">
              <div className="sponsers mt-30">
                <div className="title me-5 flex-shrink-0">
                  Multi <br /> <span className="fw-bold"> Env. </span>
                </div>
                <div className="sponsers-content">
                  <a href="#">
                    <img height="21px" src="/assets/img/logos/asterisk.png" alt="" />
                  </a>
                  <a href="#">
                    <img height="21px" src="/assets/img/logos/freeswitch.svg" alt="" />
                  </a>
                  <a href="#">
                    <img height="21px" src="/assets/img/logos/kamailio.svg" alt="" />
                  </a>
                  <a href="#">
                    <img height="21px" src="/assets/img/logos/opensips.png" alt="" />
                  </a>
                  <a href="#">
                    <img height="21px" src="/assets/img/logos/sipxecs.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src="/assets/img/header/head7_bg.png" alt="" className="head7_bg slide_up_down" />
    </header>
  )
}

export default Header;